<!--
 * @Description: 
 * @Author: lynn
 * @Date: 2023-05-18 15:48:13
 * @LastEditTime: 2023-05-18 16:14:50
 * @LastEditors: lynn
-->
<template>
  <div class="banner">
    <div class="banner-item"> <img src="../../assets/images/banner@2x.png" alt=""> </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.banner{
  width: 100%;
  &-item{
    img{
      width: 100%;
    }
  }

}
</style>