<!--
 * @Description: 
 * @Author: lynn
 * @Date: 2022-01-07 17:24:44
 * @LastEditTime: 2023-07-05 15:23:04
 * @LastEditors: lynn
-->
<template>
    <div class="page">
        <banner id="home" />
        <about id="about" />
        <!-- <product id="product" /> -->
    </div>
</template>

<script>

import Banner from './banner.vue'
import About from './about.vue'
// import Product from './product.vue'

export default {
    components: { 
        Banner,
        About, 
        // Product 
    },
    mounted() {
        $(window).scroll(function(event){
            const { scrollY } = event.currentTarget
            console.log(scrollY)
            // if(  scrollY <= 300){
            //     $('#intro-text p, #intro-text h3,#intro-img img').animate({opacity: 1, left: 0})
            // }
            if( scrollY > 1100 && scrollY < 1600 ){
                $('#content-apply .item').animate({opacity: 1, left: 0})
                $('#content-apply .item img, #content-apply .item h4, #content-apply .item p').delay(500).animate({opacity: 1, left: 0})     
            }
            if( scrollY > 1600 ){
                 $('#aplly .item').animate({opacity: 1, left: 0})
                $('#aplly .item img, #aplly .item h4, #aplly .item p').delay(500).animate({opacity: 1, left: 0})     
            }
        })
    }

   
}
</script>

<style lang="less" scoped>
.page{
    // background-image: url('../../assets/bg.jpg');
    // display: flex;
    // align-items: center;
    // text-align: center;
    // justify-content: space-around;
    // h3{
    //     font-size: 60px;
    //     font-family: Microsoft YaHei;
    //     font-weight: bold;
    //     color: #FFFFFF;
    //     line-height: 12px;
    // }
    overflow-x: hidden;
}
.container{
  padding: 30px 46px;
  .slideLeft{
    animation: slideLeftAnimate 1s ;
}
}

@media only screen and (max-width: 992px) {
  .container{
    padding: 15px;
  }
}

@keyframes slideLeftAnimate
{
from {
    opacity: 0;
    transform: translateX(60px);
}
to {
    transform: translateX(0px);
    opacity: 1;
}
}
</style>