<!--
 * @Description: 关于我们
 * @Author: lynn
 * @Date: 2023-05-18 16:21:22
 * @LastEditTime: 2023-05-30 16:42:12
 * @LastEditors: lynn
-->
<template>
  <div class="container about">
    <base-title title="关于我们" />
      <a-row class="intro" :gutter="[0, 0]">
        <a-col id="intro-text" class="intro-text" :xs="{ span: 24}" :xl="{ span: 14}">
          <h3>成都中科星天科技有限公司</h3>
          <p>成都中科星天科技有限公司，位于中国四川省成都市，是一家专注于三维太空数字孪生领域的高新技术企业。公司致力于为特殊部门提供创新的三维太空数字孪生解决方案，推动国家安全和太空探索事业的发展。</p>
          <p>成都中科星天公司汇集了一批国内顶尖专家，拥有丰富的太空科学研究及软件工程实践经验。公司凭借先进的三维数字孪生技术，为客户提供从方案设计、软件研发、系统集成到运行维护的全方位服务。主要业务领域包括三维太空数字孪生建模与仿真、空间环境监测系统、太空探测器数据处理与分析、航天通信与导航软件系统等。</p>
          <p>作为国内领先的三维太空数字孪生体服务商，成都中科星天关注技术创新，致力于推动产业发展。公司积极参与国家重大科技项目，与特殊部门及科研院所紧密合作，共同推进国家太空战略的实施。</p>
          <p>通过不断探索与创新，成都中科星天立志成为国内乃至国际三维太空数字孪生技术的领军企业，为推动人类太空事业的进步贡献力量。</p>
        </a-col>
        <a-col id="intro-img" class="intro-img" :xs="{ span: 24}" :xl="{ span: 10}">
          <!-- <div> -->
          <img src="../../assets/images/pic@2x.png"/>
          <!-- </div> -->
        </a-col>
      </a-row>
  </div>
</template>

<script>
import baseTitle from '../../components/base-title/base-title.vue'

export default {
  components: { baseTitle },
 
}
</script>

<style lang="less" scoped>
.about{
  background: #f7f8fa;
}
.intro{
  display: flex;
  padding-top: 20px;
  
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
   
    // opacity: 0;
    // left: 200px;
    // position: absolute;
    
  }
  h3{
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0.1px;
    line-height: 30px;
    text-align: left;
    color: #013f8a;
    margin-bottom: 20px;
    // left: 200px;
    // opacity: 0;
    // position: relative;
  }
  p{
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 30px;
    text-align: left;
    color: #333;
    margin-bottom: 0;
    // left: 200px;
    // opacity: 0;
    // position: relative;
  }
  &-text{
   padding: 40px 52px 30px 40px!important;
   background-color: #fff;
   min-height: 393px;
  }

}
@media only screen and (max-width: 992px) {
  .intro{
    display: block;
    &-text{
      padding: 15px!important;
    }
  }
}
</style>